.web-part {
    display: flex;
    @media only screen and (max-width: 780px) {
        display: none !important;
    }
}
.mobile-part {
    display: none !important;
    @media only screen and (max-width: 780px) {
        display: flex !important;
    }
}

.about-container {
    width: 100%;
    position: relative;
    background-image: url(../../assets/images/two-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

    @media only screen and (max-width: 780px) {
        min-height: calc(30vh + 100px + 200px);
    }

    .content-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 40vh;

        @media only screen and (max-width: 780px) {
            padding-top: 30vh;
        }

        .content-head {
            font-size: 50px;
            color: #fff;
            text-align: center;
            margin-bottom: 40px;

            @media only screen and (max-width: 780px) and (min-width: 630px) {
                font-size: 40px;
            }

            @media only screen and (max-width: 629px) and (min-width: 460px) {
                font-size: 30px;
            }

            @media only screen and (max-width: 459px) {
                font-size: 25px;
            }
        }

        .content-desc {
            font-size: 16px;
            color: #fff;
            text-align: center;
            margin-top: 30px;
            max-width: 50%;
            line-height: 25px;

            @media only screen and (max-width: 780px) {
                max-width: 90%;
            }
        }

        .graph-image-container {
            max-width: 90%;
            position: relative;

            @media only screen and (max-width: 842px) {
                width: 90%;
                height: auto;
            }

            img {
                width: 100%;
                height: 100%;
            }

            .animation-border {
                position: absolute;
                top: 0;
                width: 15.5%;
                height: 54%;
                border: 2px solid red;
                left: 24%;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 3px;
                // animation: changeBorders 2s linear infinite;
            }

            .animation-border::after {
                width: 2px;
                background-color: #2C2CA7;
                height: 0px;
                position: absolute;
                bottom: 0;
                left: -2px;
                z-index: 1;
                animation: movedown 2s linear infinite;
                animation-fill-mode: forwards;
                content: '';
                display: inline-block;
            }
        }
    }
}

.about-tiles {
    position: absolute;
    bottom: 0vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 750px;
    flex-wrap: wrap;

    @media only screen and (max-width: 780px) and (min-width: 501px) {
        position: relative;
        width: auto;
    }

    @media only screen and (max-width: 500px) {
        position: relative;
        width: auto;
    }

    .tile-item {
        width: 220px;
        height: 220px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 5px;
        padding: 20px 22px;
        background-color: #fff;
        margin: 10px;

        @media only screen and (max-width: 500px) {
            width: 172px;
            height: 172px;
        }

        .tile-image {
            width: 40px;
            height: 40px;
            margin-bottom: 10px;
        }

        .tile-desc {
            font-size: 15px;
            color: #000;
            line-height: 22px;

            @media only screen and (max-width: 500px) {
                font-size: 12px;
            }
        }
    }
}

@keyframes movedown {
    0% {
        height: calc(100% + 10px);
        width: 2px;
        bottom: -2px;
        left: -2px;
    }

    25% {
        height: 2px;
        width: 2px;
        bottom: -2px;
        left: -2px;
    }

    25.01% {
        height: 2px;
        width: calc(100% + 10px);
        bottom: -2px;
        left: -2px;
    }

    50% {
        height: 2px;
        width: 0%;
        left: 100%;
        bottom: -2px;
    }

    50.01% {
        height: calc(100% + 10px);
        width: 2px;
        left: 100%;
        bottom: -2px;
    }

    75% {
        height: 0;
        width: 2px;
        left: 100%;
        bottom: 100%;
    }

    75.01% {
        height: 2px;
        width: calc(100% + 10px);
        left: 0%;
        bottom: 100%;
    }

    99.01% {
        height: 2px;
        width: 0;
        left: 0;
        bottom: 100%;
    }
}