.free-trial-container {
    min-height: 100vh;

    .header-container {
        width: 100%;
        height: 50vh;
        margin-top: 64px;
        background-image: linear-gradient(to right, #0000F8, #00009D);
        padding: 0 9vw;

        .header-content {
            margin-top: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            padding: 30px 0;

            .back-container {
                position: absolute;
                width: 30px;
                height: 30px;
                top: 50%;
                transform: translateY(-50%);
                left: 0px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .first-head {
                font-size: 2.8vw;
                color: #fff;
                font-weight: 500;

                @media only screen and (max-width: 600px) {
                    font-size: 4vw;
                }
            }

            .second-head {
                font-size: 1.2vw;
                color: #fff;
                margin-top: 5px;

                @media only screen and (max-width: 600px) {
                    font-size: 2vw;
                }
            }
        }
    }

    .form-container {
        width: 100%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 25px;

        .iframe-style {
            width: 100%;
            min-height: 100vh;
            height: auto;
            border: none;
            padding: 0;
        }

        .header {
            font-size: 22px;
            color: #4D4C4C;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .form-flex {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 20px;

            .textarea-item {
                width: 100% !important;
            }

            .form-item {
                width: 49%;
                margin-bottom: 20px;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                }

                .label {
                    font-size: 12px;
                    color: #4D4C4C;
                    margin-bottom: 5px;
                }

                .form-input {
                    width: 100%;
                    height: 40px;
                    border-radius: 5px;
                    background-color: #F1F0F0;
                    border: none;
                    padding: 0 15px;
                }

                .form-textarea {
                    width: 100%;
                    height: 100px;
                    border-radius: 5px;
                    background-color: #F1F0F0;
                    border: none;
                    padding: 15px;
                }

                .required {
                    color: red;
                    font-size: 12px;
                }

                .form-input::placeholder {
                    font-size: 12px;
                }

                .form-input:focus {
                    outline: none;
                }

                .form-textarea::placeholder {
                    font-size: 12px;
                }

                .form-textarea:focus {
                    outline: none;
                }
            }
        }

        .already-loggedin {
            display: flex;
            justify-content: flex-end;

            .inner {
                width: 50%;
                display: flex;
                justify-content: center;
                font-size: 12px;
                color: #4D4C4C;
                font-weight: 500;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                }

                .link {
                    color: #00009D;
                    text-decoration: underline;
                    margin: 0 3px;
                    cursor: pointer;
                }
            }

        }
    }
}

.thanks-container {
    width: 100%;
    min-height: 100vh;
    position: fixed;

    .thanks-modal-container {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 9999;

        .modal-content {
            position: absolute;
            width: 50%;
            max-width: 700px;
            top: 30%;
            left: 50%;
            transform: translateX(-50%);
            background-color: #fff;
            border-radius: 10px;
            border-top: 10px solid #0000F4;
            padding: 50px;

            .close-container {
                width: 18px;
                height: 18px;
                position: absolute;
                top: 20px;
                right: 20px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .modal-head {
                font-size: 24px;
                color: #1B1B1B;
                margin-bottom: 15px;
            }

            .modal-desc {
                font-size: 12px;
                color: #444444;
                margin-bottom: 20px;
            }

            .modal-note {
                font-size: 10px;
                color: #707070;

                .email {
                    color: #0000F4;
                    cursor: pointer;
                }
            }
        }

        .terms {
            overflow-y: auto;
            height: 80vh;
            top: 10vh;

            .terms-desc {
                font-size: 12px;
                color: #444444;
                margin-bottom: 12px;
                line-height: 20px;
            }

            .space {
                padding-left: 7px;
            }

            .space-more {
                color: #fff;
            }

            .start-margin {
                padding-left: 20px;
            }

            .terms-head {
                font-size: 14px;
                color: #444444;
                font-weight: 600;
                margin-bottom: 12px;
                text-align: center;
            }
        }
    }
}