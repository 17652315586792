.footer-container {
    .get-started {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .message {
            display: flex;
            cursor: pointer;

            .top-message {
                font-size: 60px;
                color: #00009D;
                margin-right: 15px;
                margin-bottom: 10px;

                @media only screen and (max-width: 449px) {
                    font-size: 50px;
                }
            }

            .small-message {
                color: #00009D;
                font-size: 15px;
                text-align: right;
                padding-right: 15px;
            }

            .started-image {
                width: 70px;
                height: 70px;

                @media only screen and (max-width: 449px) {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }

    .footer {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: #0101A1;
        padding: 35px 4%;

        .left-container {
            display: flex;
            align-items: flex-end;

            .details {
                color: #fff;
                font-size: 14px;
                margin: 10px 0;
            }

            .details-underlined {
                color: #fff;
                font-size: 14px;
                text-decoration: underline;
                margin: 10px 0;
            }
        }

        .copyright {
            color: #fff;
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
}