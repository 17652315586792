.navbar-container {
    height: 64px;
    border-bottom: 1px solid #C6C6C6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: #fff;

    .logo-container {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            width: 100%;
        }
    }

    .login-button {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
        img {
            width: 14px;
            height: 14px;
            margin-left: 6px;
        }
    }
}