.home-container {
    min-height: 100vh;
    padding: 0 4%;
    display: flex;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    margin-bottom: 5vh;

    @media only screen and (max-width: 780px) {
        flex-direction: column;
        padding: 0;
        justify-content: center;
    }

    .content-container {
        max-width: 40%;

        @media only screen and (max-width: 780px) {
            max-width: 100%;
            margin-top: calc(10vh + 64px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .first-line {
            font-size: 4vw;
            color: #000000;
            margin-bottom: 10px;
            
            @media only screen and (max-width: 780px) {
                font-size: 6vw;
            }
        }

        .second-line {
            font-size: 4vw;
            margin-bottom: 10px;
            background: linear-gradient(to right, #00009D, #0000F8, #3939FF, #0000F8, #00009D);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media only screen and (max-width: 780px) {
                font-size: 6vw;
            }
        }

        .desc {
            font-size: 15px;
            color: #2F2F2F;
            line-height: 25px;
            @media only screen and (max-width: 780px) {
                max-width: 50%;
                text-align: center;
            }
        }

        .home-buttons {
            display: flex;
            align-items: center;
            margin-top: 30px;

            .free-trial {
                background-image: linear-gradient(to right, #0000F8, #00009D);
                padding: 10px 20px;
                border-radius: 10px;
                margin-right: 15px;
                color: #fff;
                cursor: pointer;
            }

            .request-demo {
                background-color: transparent;
                border: 1px solid #0101EE;
                color: #0101EE;
                border-radius: 10px;
                padding: 10px 20px;
                cursor: pointer;
            }
        }

        .add-message {
            color: #A7A7A7;
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .right-image {
        width: 100%;
        position: absolute;
        right: 0;
        height: 100vh;
        transform: translateX(40%);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @media only screen and (max-width: 780px) {
            display: none;
        }
    }

    .video-container {
        position: absolute;
        width: 53%;
        right: 2%;
        top: 50%;
        transform: translateY(-50%);

        @media only screen and (max-width: 780px) {
            position: relative;
            width: 95%;
            right: 0;
            top: 80px;
            transform: none;
        }

        video {
            width: 100%;
            height: 100%;
            border-top: 30px solid #EFEFFF;
            border-bottom: 30px solid #EFEFFF;
            border-left: 20px solid #EFEFFF;
            border-right: 20px solid #EFEFFF;
            border-radius: 15px;
        }
    }
}