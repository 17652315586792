.sets-apart-container {
    padding: 0 10vw;
    margin-top: 20vh;
    margin-bottom: 5vh;

    @media only screen and (max-width: 1175px)and (min-width: 975px) {
        padding: 0 6vw;
    }

    @media only screen and (max-width: 974px) {
        padding: 0 3vw;
    }

    .heading {
        color: #00009D;
        font-size: 50px;
        text-align: right;
        margin-left: auto;
        margin-right: 70px;
        line-height: 70px;
        margin-bottom: 100px;
    }

    .tiles {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .tiles-item {
            width: calc(25% - 20px);
            margin: 0 10px;
            background-color: #f4f4ff;
            border-radius: 10px;
            padding: 0 30px;
            padding-bottom: 40px;
            max-width: 225px;
            align-self: stretch;
            margin-bottom: 70px;

            @media only screen and (max-width: 974px) and (min-width: 450px) {
                width: calc(50% - 20px);
            }

            @media only screen and (max-width: 449px) {
                width: calc(100% - 20px);
            }

            .tiles-img {
                width: 75px;
                height: 75px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-top: -25px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                margin-bottom: 20px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .tiles-head {
                font-size: 25px;
                color: #000000;
                margin-bottom: 10px;
                max-width: 70%;
                line-height: 33px;
                margin-bottom: 15px;
            }

            .tiles-desc {
                font-size: 15px;
                color: #00009D;
                margin-bottom: 10px;
                line-height: 27px;
            }
        }
    }
}