.whos-using-container {
    min-height: 100vh;
    width: 100%;
    position: relative;
    background-image: url(../../assets/images/whos-using-bg.png);
    background-size: cover;
    background-repeat: repeat-x;
    background-position: center;
    margin-top: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .content-container {
        max-width: 400px;
        margin: 0 10%;
        padding-top: 0;

        @media only screen and (max-width: 500px) {
            padding-top: 100px;
        }

        .heading {
            color: #fff;
            font-size: 60px;
            line-height: 70px;
            text-align: right;
        }

        .desc {
            color: #fff;
            font-size: 16px;
            line-height: 25px;
            margin-top: 30px;
        }

        .tiles {
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;

            .tiles-item {
                color: #fff;
                font-size: 15px;
                background-color: rgba(233, 233, 233, 0.32);
                border-radius: 50px;
                padding: 10px 20px;
                margin-bottom: 12px;
                margin-right: 12px;
            }
        }
    }

    .background-images {
        position: relative;
        width: 100%;
        min-height: 100vh;
        top: 0;
        left: 0;
        z-index: -1;

        .bg-img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
        }

        .bg-color-img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            opacity: 0.75;
        }
    }
}

.whos-using-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.25;
    z-index: 10;
    width: 100%;
    min-height: 100vh;
    background-image: url(../../assets/images/whos-using.png);
    background-size: cover;
    background-repeat: repeat-x;
    background-position-y: bottom;
}